import {
  CandidateLegalTechSkill,
  Experience,
  ExperienceFocusAreaOrderPreference,
  ExperienceTaxonomy,
  ExperienceTaxonomyFocusArea,
  ExperienceTaxonomyLegalSkill,
  Taxonomy,
  TaxonomyLegalSkillEnum,
  TaxonomySkillGroupEnum,
} from '@axiom/validation';

interface DisplayableLegalTechSkills {
  [category: string]: string[];
}

export const CandidateTaxonomyUtil = {
  getDisplayableLegalTechSkills(
    legalTechSkills: CandidateLegalTechSkill[] | null
  ): DisplayableLegalTechSkills {
    if (!legalTechSkills?.length) {
      return {};
    }

    const legalTechSkillsMap =
      legalTechSkills.reduce<DisplayableLegalTechSkills>(
        (acc, { legalTechCategory, legalTechSkill }) => {
          if (!legalTechCategory || !legalTechSkill) {
            return acc;
          }

          if (!acc[legalTechCategory]) {
            acc[legalTechCategory] = [];
          }

          acc[legalTechCategory].push(legalTechSkill);
          return acc;
        },
        {}
      );

    for (const category in legalTechSkillsMap) {
      legalTechSkillsMap[category] = [
        ...new Set(legalTechSkillsMap[category]),
      ].sort((skillA, skillB) =>
        skillA.toLowerCase().localeCompare(skillB.toLowerCase())
      );
    }

    return Object.fromEntries(
      Object.entries(legalTechSkillsMap).sort(([categoryA], [categoryB]) =>
        categoryA.toLowerCase().localeCompare(categoryB.toLowerCase())
      )
    );
  },

  groupExperienceTaxonomyByFocusArea: (
    experienceTaxonomy: Experience['experienceTaxonomy'],
    experiencesFocusAreaOrderPreferences: Experience['experiencesFocusAreaOrderPreferences']
  ) => {
    type ByFocusArea = {
      focusArea: string;
      skillGroups: {
        skillGroup: string;
        legalSkills: string[];
      }[];
    };

    // Sorting used for skill groups, legal skills, and unordered focus areas
    const lowerCaseSort = (a: string, b: string) => {
      const lowerA = a.toLowerCase();
      const lowerB = b.toLowerCase();
      if (lowerA < lowerB) return -1;
      if (lowerA > lowerB) return 1;
      return 0;
    };

    const expTax: ByFocusArea[] = [];

    const experienceTaxonomyMap = experienceTaxonomy.reduce(
      (acc, curr) => {
        const { focusArea, skillGroup, legalSkills } = curr;
        if (curr.dsSuggestion) {
          return acc;
        } else if (acc[focusArea]) {
          if (skillGroup) {
            acc[focusArea].skillGroups.push({
              skillGroup,
              legalSkills: legalSkills.sort(lowerCaseSort),
            });
            acc[focusArea].skillGroups.sort((a, b) =>
              lowerCaseSort(a.skillGroup, b.skillGroup)
            );
          }
        } else {
          acc[focusArea] = {
            focusArea,
            skillGroups: skillGroup
              ? [
                  {
                    skillGroup,
                    legalSkills: legalSkills.sort(lowerCaseSort),
                  },
                ]
              : [],
          };
        }
        return acc;
      },
      {} as Record<string, ByFocusArea>
    );

    // order focus areas if there is a preference
    // There can only be 0 or 1 order preferences
    if (experiencesFocusAreaOrderPreferences[0]?.focusAreas) {
      const { focusAreas } = experiencesFocusAreaOrderPreferences[0];
      focusAreas.forEach(fa => {
        if (experienceTaxonomyMap[fa]) {
          expTax.push({ ...experienceTaxonomyMap[fa] });
          delete experienceTaxonomyMap[fa];
        }
      });
    }

    // unordered values show at the end
    return [
      ...expTax,
      ...Object.keys(experienceTaxonomyMap)
        .sort(lowerCaseSort)
        .map(k => experienceTaxonomyMap[k]),
    ];
  },

  getFocusAreasFromExperienceTaxonomy: (
    experienceTaxonomy: Experience['experienceTaxonomy']
  ): ExperienceTaxonomyFocusArea[] =>
    experienceTaxonomy.reduce(
      (acc, curr) => [
        ...acc,
        ...(curr.skillGroup ? [] : [curr as ExperienceTaxonomyFocusArea]),
      ],
      [] as ExperienceTaxonomyFocusArea[]
    ),

  getLegalSkillsExperienceTaxonomy: (
    experienceTaxonomy: Experience['experienceTaxonomy']
  ): ExperienceTaxonomyLegalSkill[] =>
    experienceTaxonomy.reduce(
      (acc, curr) => [
        ...acc,
        ...(curr.skillGroup ? [curr as ExperienceTaxonomyLegalSkill] : []),
      ],
      [] as ExperienceTaxonomyLegalSkill[]
    ),

  convertLegalSkillsToExperienceTaxonomy: (
    legalSkills: TaxonomyLegalSkillEnum[],
    experienceId: Experience['id'],
    taxonomy: Taxonomy
  ) => {
    const experienceTaxonomyBySkillGroup = legalSkills.reduce(
      (acc, legalSkill) => {
        const { skillGroup } = taxonomy.legalSkills[
          legalSkill
        ] as Taxonomy['legalSkills'][string];
        if (acc[skillGroup]) {
          acc[skillGroup].legalSkills?.push(legalSkill);
        } else {
          const { focusArea } = taxonomy.skillGroups[
            skillGroup
          ] as Taxonomy['skillGroups'][string];
          const { practiceArea } = taxonomy.focusAreas[
            focusArea
          ] as Taxonomy['focusAreas'][string];

          acc[skillGroup] = {
            experienceId,
            practiceArea,
            focusArea,
            skillGroup,
            legalSkills: [legalSkill],
            dsSuggestion: false,
          } as ExperienceTaxonomy;
        }
        return acc;
      },
      {} as Record<TaxonomySkillGroupEnum, ExperienceTaxonomy>
    );

    return Object.keys(experienceTaxonomyBySkillGroup).reduce(
      (acc, key) => {
        if (experienceTaxonomyBySkillGroup[key]) {
          acc.push(experienceTaxonomyBySkillGroup[key]);
        }
        return acc;
      },
      [] as Experience['experienceTaxonomy']
    );
  },

  orderExperienceTaxonomyByFocusAreaOrderPreferences: (
    experienceTaxonomy: Experience['experienceTaxonomy'],
    experiencesFocusAreaOrderPreferences: Experience['experiencesFocusAreaOrderPreferences']
  ) => {
    if (experiencesFocusAreaOrderPreferences.length === 0) {
      return experienceTaxonomy;
    }
    const { focusAreas: focusAreaOrder } =
      experiencesFocusAreaOrderPreferences[0] as ExperienceFocusAreaOrderPreference;

    return experienceTaxonomy.sort((a, b) => {
      return (
        focusAreaOrder.indexOf(a.focusArea) -
        focusAreaOrder.indexOf(b.focusArea)
      );
    });
  },

  getSkillsCount: (
    experienceTaxonomy: Experience['experienceTaxonomy'],
    generalSkills: Experience['generalSkills']
  ) => {
    const generalSkillsCount = generalSkills?.length ?? 0;

    return (
      experienceTaxonomy.reduce((acc, curr) => {
        if (Array.isArray(curr.legalSkills) && !curr.dsSuggestion) {
          acc += curr.legalSkills.length;
        }

        return acc;
      }, 0) + generalSkillsCount
    );
  },

  showExperienceTaxonomy: (experience: Experience) => {
    return (
      CandidateTaxonomyUtil.getLegalSkillsExperienceTaxonomy(
        experience.experienceTaxonomy
      ).some(({ dsSuggestion }) => !dsSuggestion) ||
      (experience.generalSkills?.length ?? 0) > 0
    );
  },
};
