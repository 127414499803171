import { z } from 'zod';
import { TaxonomyConst } from '@axiom/const';
import { ZodArrayToEnum } from '@axiom/types';

export const CandidateLegalTechSkillProficiencies = Object.values(
  TaxonomyConst.LegalTechSkillsProficiencies
);

export const CandidateLegalTechSkillSchema = z.object({
  id: z.string().uuid(),
  legalTechCategory: z.string(),
  legalTechSkill: z.string(),
  proficiency: z.enum(ZodArrayToEnum(CandidateLegalTechSkillProficiencies)),
});

export type CandidateLegalTechSkill = z.infer<
  typeof CandidateLegalTechSkillSchema
>;
