import { z } from 'zod';

import { CandidateSchema } from './candidate';
import { CandidateOpportunitySchema } from './candidate-opportunities';
import { PositionSchema } from './position';

export const OpportunityPositionSchema = PositionSchema.extend({
  candidates: z.array(CandidateSchema.merge(CandidateOpportunitySchema)),
});

export type OpportunityPosition = z.infer<typeof OpportunityPositionSchema>;
